.tile {
  width: 20%;
  height: auto;
  display: inline-block;
  background-size: cover;
  cursor: pointer;
  position: relative;

  padding: 0 0 19% 0;
  background-position: center;
}

.image-title {
  width: 100%;
  height: 25%;
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 1rem 1rem;
  font-size: 0.9em;
  font-family: "Helvetica";
  text-align: center;
  line-height: 1em;
  background-color: rgba(0, 0, 0, 0.5);
}

.tile .image-title {
  transition: 0.8s ease;
}

/* Hover effect on image tiles */

.tile:hover .image-title {
  background-color: rgba(0, 0, 0, 0.8);
  height: 40%;
}

/* Pop up CSS light box */

/* Responsive tiles starts here. Breaking points: 1280, 1024, 600, 360 */

@media screen and (max-width: 2000px) {
  .image-container .tile {
    width: 20%;
    padding-bottom: 20%;
    margin-left: 70px;
    margin-top: 50px;
  }
  .main-gallery {
    width: 85%;
    margin-left: 18%;
  }
}

@media screen and (max-width: 1024px) {
  .image-container .tile {
    width: 30%;
    padding-bottom: 30%;

    font-size: 0.8em;
    margin-left: 10px;
  }
  .main-gallery {
    max-width: 90%;
    margin-left: 5%;
  }
}

@media screen and (max-width: 740px) {
  .image-container .tile {
    width: 30%;
    padding-bottom: 30%;

    font-size: 0.8em;
    margin-left: 10px;
  }
  .main-gallery {
    max-width: 90%;
    margin-left: 5%;
  }
}

@media screen and (max-width: 600px) {
  .image-container .tile {
    width: 30%;
    padding-bottom: 30%;

    font-size: 0.8em;
    margin-left: 10px;
  }
  .main-gallery {
    max-width: 90%;
    margin-left: 5%;
  }
}

@media screen and (max-width: 360px) {
  .image-container .tile {
    width: 30%;
    padding-bottom: 30%;

    font-size: 0.8em;
    margin-left: 10px;
  }
  .main-gallery {
    max-width: 90%;
    margin-left: 5%;
  }
}

@media screen and (max-width: 1300px) {
  .image-title {
    width: 100%;
    height: 58%;
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 1rem 1rem;
    font-size: 22.9em;
    font-family: "Helvetica";
    text-align: center;
    line-height: 1em;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
