.App {
  text-align: center;
}
body {
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 2000px) {
  .carousel .slide img {
    width: 45% !important;
    vertical-align: top;
    border: 0;
  }

  .carousel .control-next.control-arrow {
    right: 27% !important;
  }
  .carousel .control-prev.control-arrow {
    left: 27% !important;
  }
  .calender {
    width: 100%;

    margin-left: 0%;
    margin-top: 50px;
  }
  .funeral-container {
    display: flex;
    /* margin-left: 2%; */
  }
  .map-mobile {
    display: none;
  }
  .container {
    width: 50%;
    height: 80%;
    position: absolute;
    top: 125px;
    left: 790px;
    right: 0;
    bottom: 0;
    overflow: hidden;
    text-align: center;
  }
  .rise-text {
    position: absolute;
    text-align: center;
    margin-left: 41.5%;
    margin-top: -5%;

    opacity: 0;
    color: rgb(0, 0, 0);
    border-bottom: 0;
    font-family: Trebuchet MS, sans-serif;
    font-size: 40px;
    font-weight: normal;
    -webkit-animation-name: rise;
    -webkit-animation-duration: 5s;
    -webkit-animation-delay: 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: cubic-bezier(0, 0.25, 0.55, 1);
    -webkit-animaiton-fill-mode: forwards;
    animation-name: rise;
    animation-duration: 5s;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0, 0.25, 0.55, 1);
    animation-fill-mode: forwards;
  }
  .bg-image {
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    width: 35%;
    background-size: cover;
    background-position: center center;
  }
  .web-map {
    margin-right: 500px;
  }
  .carousel .thumbs-wrapper {
    overflow: hidden;
  }
  .slider-text {
    position: absolute;
    top: 105%;
    left: 120px;
  }
}
@media (max-width: 768px) {
  .carousel .slide img {
    width: 100% !important;
    vertical-align: top;
    border: 0;
  }
  P .carousel .slide img {
    width: 100% !important;
    vertical-align: top;
    border: 0;
  }
  .carousel .control-next.control-arrow {
    right: 0 !important;
  }
  .carousel .control-prev.control-arrow {
    left: 0 !important;
  }

  .calender {
    width: 100%;
    margin-left: 0;
    margin-top: 0;
  }
  .funeral-container {
    display: inline-block;
    width: 100%;
    margin-left: 0%;
  }
  .container {
    width: 100%;
    height: 80%;
    position: absolute;
    top: 185px;
    left: 20px;
    right: 0;
    bottom: 0;
    overflow: hidden;
    text-align: center;
  }

  .rise-text {
    text-align: center;
    margin: auto;
    opacity: 0;
    color: rgb(0, 0, 0);
    border-bottom: 0;
    font-family: Trebuchet MS, sans-serif;
    font-size: 24px;
    font-weight: normal;
    -webkit-animation-name: rise;
    -webkit-animation-duration: 5s;
    -webkit-animation-delay: 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: cubic-bezier(0, 0.25, 0.55, 1);
    -webkit-animaiton-fill-mode: forwards;
    animation-name: rise;
    animation-duration: 5s;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0, 0.25, 0.55, 1);
    animation-fill-mode: forwards;
  }
  .web-map {
    display: none;
  }

  .bg-image {
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    background-position: center center;
  }
  .map-mobile {
    display: block;
    margin-left: 13px;
  }
  .carousel .thumbs-wrapper {
    margin: 0 !important;
    overflow: hidden;
  }
}

.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:300");

.visitors {
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  font-size: larger;
}

.visitors:first-of-type {
  animation: showup 7s infinite;
}

.visitors:last-of-type {
  width: 0px;
  animation: reveal 7s infinite;
}

.visitors:last-of-type span {
  margin-left: -255px;
  animation: slidein 7s infinite;
}

@keyframes showup {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slidein {
  0% {
    margin-left: -255px;
  }
  20% {
    margin-left: -255px;
  }
  35% {
    margin-left: 0px;
  }
  100% {
    margin-left: 0px;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
    width: 0px;
  }
  20% {
    opacity: 1;
    width: 0px;
  }
  30% {
    width: 255px;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 255px;
  }
}

@-webkit-keyframes rise {
  0% {
    padding-top: 100%;
  }
  60% {
    padding-top: 7%;
  }
  80% {
    padding-top: 7%;
    border-bottom: none;
    opacity: 0.5;
    text-shadow: 2px 2px 5px #fff;
  }
  100% {
    padding-top: 7%;
    border-bottom: none;
    opacity: 1;
    text-shadow: 0;
  }
}

@keyframes rise {
  0% {
    padding-top: 100%;
  }
  60% {
    padding-top: 7%;
  }
  80% {
    padding-top: 7%;
    border-bottom: none;
    opacity: 0.5;
    text-shadow: 2px 2px 200px rgba(255, 255, 255, 1);
  }
  100% {
    padding-top: 7%;
    border-bottom: none;
    opacity: 1;
    text-shadow: 200px 200px 200px rgba(255, 255, 255, 0);
  }
}

.carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 20px !important;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 90%);
  color: #fff;
}
.carousel .slide .legend {
  display: none;
}
@media (max-width: 768px) {
  .rise-text {
    left: 0px;
    text-align: -webkit-center;
    margin-top: 46px;
  }
}
@media (max-width: 1024px) {
  .rise-text {
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #ffffff;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

/* Colors */
/*
dark blue: #476A6F
light blue: #258EA6
dark green: #519E8A
medium green: #7EB09B
light green: #A3C4BC
*/

.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  /* margin: 5px; */
  flex-direction: column;
  justify-content: center;
  align-items: right;
  background-color: grey;
}

.form-wrapper {
  /* width: 700px; */

  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0px 10px 50px #555;
  background-color: #ffffff;
  align-items: right;
}

form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: right;
}

h1 {
  text-align: center;
  width: 100%;
  color: #111;
  font-weight: lighter;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
/* h3 {
  text-align: center;
  width: 100%;
  margin-top: 30px;
  background-color: blue;
  color: #111;
  font-weight: lighter;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
} */

label {
  font-size: 16px;
  /* margin-bottom: 0.25em; */
  color: #2b547e;
  font-weight: lighter;
  text-align: right;
}

input {
  padding: 20px 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #cfcfcf;
  text-align: right;
}

input::placeholder {
  font-size: 1em;
  font-weight: lighter;
  color: #999;
  text-align: right;
}

input.error {
  border: 1px solid red;
}

.errorMessage {
  color: red;
  font-size: 0.75em;
  display: relative;
  text-align: right;
}

.mobilegroub,
.mobile {
  margin-left: 1%;
  text-align: right;
}
.kids,
.mobilegroub,
.address,
.fullName,
.mobile,
.email,
.password {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.birthDay {
  display: flex-end;
  align-self: right;
  flex-direction: column;
  margin-bottom: 15px;
}

.mobile {
  width: 49%;
}
.email {
  width: 66.5%;
}

.mobilegroub {
  width: 32.3%;
}
.kids {
  width: 30%;
}

.address,
.fullName,
.password {
  width: 100%;
}

.createAccount {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.createAccount button {
  background-color: #519e8a;
  color: #fff;
  border: 2px solid #fff;
  width: 100%;
  margin-top: 1em;
  padding: 8px 0px;
  font-size: 1em;
  font-weight: lighter;
  letter-spacing: 1px;
  margin-bottom: 0.25em;
}

.createAccount button:hover {
  color: #519e8a;
  background-color: #fff;
  border: 2px solid #519e8a;
}

.createAccount small {
  color: #999;
  font-weight: lighter;
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .rise-text {
    left: -30px;
  }
  #box {
    margin-left: 32%;
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .rise-text {
    left: -30px;
  }
  #box {
    margin-left: 32%;
  }
}
