@import url("https://fonts.googleapis.com/css?family=Raleway:200");
@media (max-width: 768px) {
  #box {
    display: flex;
    align-items: center;
    justify-content: center;
    display: block;
    color: white;
    margin-top: 20px;
    font-family: "Raleway";
    font-size: 2.5rem;
    height: 283px;
    margin-left: 26%;
    margin-right: 30px;
    width: 363px;
  }
  .gradient-border {
    --borderWidth: 3px;
    background: white;
    position: relative;
    border-radius: var(--borderWidth);
  }
  .gradient-border:after {
    content: "";
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: #787878;
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
}

.swiper-container {
  width: 350px;
  height: 350px;
  padding: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

/* counter code */
@import url(https://fonts.googleapis.com/css?family=Lato:400,700|Montserrat:400,700);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css);

/*/ start count stats /*/

section#counter-stats {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.stats {
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

.stats .fa {
  color: #008080;
  font-size: 40px;
}

/*/ end count stats /*/
@import url(https://fonts.googleapis.com/earlyaccess/droidarabicnaskh.css);
/*font-family: 'Droid Arabic Naskh', serif;*/

.hline {
  height: 1px;

  background: rgba(100, 100, 200, 0.5);

  /*border-top: 1px solid rgba(200,200,200,0.2);*/
}
.bodycss {
  background: rgba(149, 147, 138, 0.698);
  height: 47px;
  width: 317px;
  -moz-border-radius-bottomleft: 20px;
  -moz-border-radius-topleft: 20px;
  font-family: "Droid Arabic Naskh , serif";
  line-height: 50px;
  font-size: 22px;
  color: rgb(255, 255, 255);
}
.tile1 {
  text-align: center;
  margin-top: 10px;
}

.tile2 {
  text-align: center;
  margin-top: 50px;
}

@media screen and (max-width: 2000px) {
  .weddign {
    margin-left: 30px;
  }
  .tile1 {
    margin-top: -5%;
    position: absolute;
    margin-left: 48.8%;
  }

  .tile2 {
    text-align: center;
    margin-top: 150px;
    margin-left: 50px;
  }
  #box {
    display: flex;
    align-items: center;
    justify-content: center;
    display: block;
    color: white;
    margin-top: 20px;
    font-family: "Raleway";
    font-size: 2rem;
    height: 420px;
    margin-left: 35%;
    width: 530px;
  }
  .gradient-border {
    --borderWidth: 3px;
    background: white;
    position: relative;
    border-radius: var(--borderWidth);
  }
  .gradient-border:after {
    content: "";
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: #787878;

    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
}

@media screen and (max-width: 1024px) {
  #box {
    display: flex;
    align-items: center;
    justify-content: center;
    display: block;
    color: white;
    margin-top: 20px;
    font-family: "Raleway";
    font-size: 2.5rem;
    height: 420px;
    margin-left: 12%;
    margin-right: 50px;
    width: 543px;
  }
  .gradient-border {
    --borderWidth: 3px;
    background: white;
    position: relative;
    border-radius: var(--borderWidth);
  }
  .gradient-border:after {
    content: "";
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: #787878;
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
}
@media screen and (max-width: 1364px) {
  #box {
    display: flex;
    align-items: center;
    justify-content: center;
    display: block;
    color: white;
    margin-top: 20px;
    font-family: "Raleway";
    font-size: 2.5rem;
    height: 420px;
    margin-left: 30%;
    margin-right: 50px;
    width: 543px;
  }
  .gradient-border {
    --borderWidth: 3px;
    background: white;
    position: relative;
    border-radius: var(--borderWidth);
  }
  .gradient-border:after {
    content: "";
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: #787878;
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
}

@media screen and (max-width: 740px) {
  #box {
    display: flex;
    align-items: center;
    justify-content: center;
    display: block;
    color: white;
    margin-top: 20px;
    font-family: "Raleway";
    font-size: 2.5rem;
    height: 280px;
    width: 365px;
  }
  .gradient-border {
    --borderWidth: 3px;
    background: white;
    position: relative;
    border-radius: var(--borderWidth);
  }
  .gradient-border:after {
    content: "";
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: #787878;
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
}
@media screen and (max-width: 600px) {
  .rise-text {
    left: 0px;
    text-align: -webkit-center;
    margin-top: -1px;
  }
  #box {
    display: flex;
    align-items: center;
    justify-content: center;
    display: block;
    color: white;
    margin-top: 20px;
    font-family: "Raleway";
    font-size: 2.5rem;
    height: 290px;
    margin-left: 1%;
    width: 385px;
  }
  .gradient-border {
    --borderWidth: 3px;
    background: white;
    position: relative;
    border-radius: var(--borderWidth);
  }
  .gradient-border:after {
    content: "";
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: #787878;
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
}

@media screen and (max-width: 1300px) {
  #box {
    display: flex;
    align-items: center;
    justify-content: center;
    display: block;
    color: white;
    margin-top: 20px;
    font-family: "Raleway";
    font-size: 2.5rem;
    height: 275px;
    margin-left: 2%;
    width: 362px;
  }
  .tile1 {
    margin-top: 20%;
    position: absolute;
    margin-left: 45%;
  }
  .tile2 {
    margin-top: 25%;
  }
  .calender {
    width: 100%;
  }
  .rise-text {
    justify-content: center;
  }

  .gradient-border {
    --borderWidth: 3px;
    background: white;
    position: relative;
    border-radius: var(--borderWidth);
  }
  .gradient-border:after {
    content: "";
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: #787878;
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  #box {
    margin-left: 32%;
    height: 402px;
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  #box {
    margin-left: 32%;
    height: 402px;
  }
}
