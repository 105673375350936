@import url(https://fonts.googleapis.com/css?family=Montserrat:300);
@import url(https://fonts.googleapis.com/css?family=Raleway:200);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700|Montserrat:400,700);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/earlyaccess/droidarabicnaskh.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}
body {
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media (max-width: 2000px) {
  .carousel .slide img {
    width: 45% !important;
    vertical-align: top;
    border: 0;
  }

  .carousel .control-next.control-arrow {
    right: 27% !important;
  }
  .carousel .control-prev.control-arrow {
    left: 27% !important;
  }
  .calender {
    width: 100%;

    margin-left: 0%;
    margin-top: 50px;
  }
  .funeral-container {
    display: -webkit-flex;
    display: flex;
    /* margin-left: 2%; */
  }
  .map-mobile {
    display: none;
  }
  .container {
    width: 50%;
    height: 80%;
    position: absolute;
    top: 125px;
    left: 790px;
    right: 0;
    bottom: 0;
    overflow: hidden;
    text-align: center;
  }
  .rise-text {
    position: absolute;
    text-align: center;
    margin-left: 41.5%;
    margin-top: -5%;

    opacity: 0;
    color: rgb(0, 0, 0);
    border-bottom: 0;
    font-family: Trebuchet MS, sans-serif;
    font-size: 40px;
    font-weight: normal;
    -webkit-animation-name: rise;
    -webkit-animation-duration: 5s;
    -webkit-animation-delay: 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: cubic-bezier(0, 0.25, 0.55, 1);
    -webkit-animaiton-fill-mode: forwards;
    animation-name: rise;
    animation-duration: 5s;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0, 0.25, 0.55, 1);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  .bg-image {
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    width: 35%;
    background-size: cover;
    background-position: center center;
  }
  .web-map {
    margin-right: 500px;
  }
  .carousel .thumbs-wrapper {
    overflow: hidden;
  }
  .slider-text {
    position: absolute;
    top: 105%;
    left: 120px;
  }
}
@media (max-width: 768px) {
  .carousel .slide img {
    width: 100% !important;
    vertical-align: top;
    border: 0;
  }
  P .carousel .slide img {
    width: 100% !important;
    vertical-align: top;
    border: 0;
  }
  .carousel .control-next.control-arrow {
    right: 0 !important;
  }
  .carousel .control-prev.control-arrow {
    left: 0 !important;
  }

  .calender {
    width: 100%;
    margin-left: 0;
    margin-top: 0;
  }
  .funeral-container {
    display: inline-block;
    width: 100%;
    margin-left: 0%;
  }
  .container {
    width: 100%;
    height: 80%;
    position: absolute;
    top: 185px;
    left: 20px;
    right: 0;
    bottom: 0;
    overflow: hidden;
    text-align: center;
  }

  .rise-text {
    text-align: center;
    margin: auto;
    opacity: 0;
    color: rgb(0, 0, 0);
    border-bottom: 0;
    font-family: Trebuchet MS, sans-serif;
    font-size: 24px;
    font-weight: normal;
    -webkit-animation-name: rise;
    -webkit-animation-duration: 5s;
    -webkit-animation-delay: 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-timing-function: cubic-bezier(0, 0.25, 0.55, 1);
    -webkit-animaiton-fill-mode: forwards;
    animation-name: rise;
    animation-duration: 5s;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-timing-function: cubic-bezier(0, 0.25, 0.55, 1);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
  }
  .web-map {
    display: none;
  }

  .bg-image {
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    background-position: center center;
  }
  .map-mobile {
    display: block;
    margin-left: 13px;
  }
  .carousel .thumbs-wrapper {
    margin: 0 !important;
    overflow: hidden;
  }
}

.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}

.visitors {
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  font-size: larger;
}

.visitors:first-of-type {
  -webkit-animation: showup 7s infinite;
          animation: showup 7s infinite;
}

.visitors:last-of-type {
  width: 0px;
  -webkit-animation: reveal 7s infinite;
          animation: reveal 7s infinite;
}

.visitors:last-of-type span {
  margin-left: -255px;
  -webkit-animation: slidein 7s infinite;
          animation: slidein 7s infinite;
}

@-webkit-keyframes showup {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes showup {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes slidein {
  0% {
    margin-left: -255px;
  }
  20% {
    margin-left: -255px;
  }
  35% {
    margin-left: 0px;
  }
  100% {
    margin-left: 0px;
  }
}

@keyframes slidein {
  0% {
    margin-left: -255px;
  }
  20% {
    margin-left: -255px;
  }
  35% {
    margin-left: 0px;
  }
  100% {
    margin-left: 0px;
  }
}

@-webkit-keyframes reveal {
  0% {
    opacity: 0;
    width: 0px;
  }
  20% {
    opacity: 1;
    width: 0px;
  }
  30% {
    width: 255px;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 255px;
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
    width: 0px;
  }
  20% {
    opacity: 1;
    width: 0px;
  }
  30% {
    width: 255px;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    width: 255px;
  }
}

@-webkit-keyframes rise {
  0% {
    padding-top: 100%;
  }
  60% {
    padding-top: 7%;
  }
  80% {
    padding-top: 7%;
    border-bottom: none;
    opacity: 0.5;
    text-shadow: 2px 2px 5px #fff;
  }
  100% {
    padding-top: 7%;
    border-bottom: none;
    opacity: 1;
    text-shadow: 0;
  }
}

@keyframes rise {
  0% {
    padding-top: 100%;
  }
  60% {
    padding-top: 7%;
  }
  80% {
    padding-top: 7%;
    border-bottom: none;
    opacity: 0.5;
    text-shadow: 2px 2px 200px rgba(255, 255, 255, 1);
  }
  100% {
    padding-top: 7%;
    border-bottom: none;
    opacity: 1;
    text-shadow: 200px 200px 200px rgba(255, 255, 255, 0);
  }
}

.carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 20px !important;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 90%);
  color: #fff;
}
.carousel .slide .legend {
  display: none;
}
@media (max-width: 768px) {
  .rise-text {
    left: 0px;
    text-align: -webkit-center;
    margin-top: 46px;
  }
}
@media (max-width: 1024px) {
  .rise-text {
    -webkit-justify-content: center;
            justify-content: center;
  }
}

@media screen and (max-width: 600px) {
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #ffffff;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

/* Colors */
/*
dark blue: #476A6F
light blue: #258EA6
dark green: #519E8A
medium green: #7EB09B
light green: #A3C4BC
*/

.wrapper {
  height: 100%;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  /* margin: 5px; */
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: right;
          align-items: right;
  background-color: grey;
}

.form-wrapper {
  /* width: 700px; */

  display: -webkit-flex;

  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0px 10px 50px #555;
  background-color: #ffffff;
  -webkit-align-items: right;
          align-items: right;
}

form {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-content: right;
          align-content: right;
}

h1 {
  text-align: center;
  width: 100%;
  color: #111;
  font-weight: lighter;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
/* h3 {
  text-align: center;
  width: 100%;
  margin-top: 30px;
  background-color: blue;
  color: #111;
  font-weight: lighter;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
} */

label {
  font-size: 16px;
  /* margin-bottom: 0.25em; */
  color: #2b547e;
  font-weight: lighter;
  text-align: right;
}

input {
  padding: 20px 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #cfcfcf;
  text-align: right;
}

input::-webkit-input-placeholder {
  font-size: 1em;
  font-weight: lighter;
  color: #999;
  text-align: right;
}

input:-ms-input-placeholder {
  font-size: 1em;
  font-weight: lighter;
  color: #999;
  text-align: right;
}

input::placeholder {
  font-size: 1em;
  font-weight: lighter;
  color: #999;
  text-align: right;
}

input.error {
  border: 1px solid red;
}

.errorMessage {
  color: red;
  font-size: 0.75em;
  display: relative;
  text-align: right;
}

.mobilegroub,
.mobile {
  margin-left: 1%;
  text-align: right;
}
.kids,
.mobilegroub,
.address,
.fullName,
.mobile,
.email,
.password {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 15px;
}

.birthDay {
  display: flex-end;
  -webkit-align-self: right;
          align-self: right;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 15px;
}

.mobile {
  width: 49%;
}
.email {
  width: 66.5%;
}

.mobilegroub {
  width: 32.3%;
}
.kids {
  width: 30%;
}

.address,
.fullName,
.password {
  width: 100%;
}

.createAccount {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.createAccount button {
  background-color: #519e8a;
  color: #fff;
  border: 2px solid #fff;
  width: 100%;
  margin-top: 1em;
  padding: 8px 0px;
  font-size: 1em;
  font-weight: lighter;
  letter-spacing: 1px;
  margin-bottom: 0.25em;
}

.createAccount button:hover {
  color: #519e8a;
  background-color: #fff;
  border: 2px solid #519e8a;
}

.createAccount small {
  color: #999;
  font-weight: lighter;
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .rise-text {
    left: -30px;
  }
  #box {
    margin-left: 32%;
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 2), screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  .rise-text {
    left: -30px;
  }
  #box {
    margin-left: 32%;
  }
}

.container{display:contents}.social-buttons{display:inline-block;background:rgba(255,255,255,0.5);padding:20px;padding-bottom:5px;border-radius:10px;text-align:center;margin:20px 10px}.social-buttons .social-margin{margin-right:15px}.social-buttons a,.social-buttons a:hover,.social-buttons a:focus,.social-buttons a:active{text-decoration:none}.social-buttons .social-icon{margin-bottom:15px;box-sizing:border-box;border-radius:138px;border:5px solid;text-align:center;width:50px;height:50px;display:inline-block;line-height:1px;padding-top:11px;transition:all 0.5s}.social-buttons .social-icon:hover{-webkit-transform:rotate(360deg) scale(1.3);transform:rotate(360deg) scale(1.3)}.social-buttons .social-icon.facebook{font-size:22px;padding-top:9px;border-color:#3b5998;background-color:#3b5998;color:#ffffff}.social-buttons .social-icon.facebook:hover{background-color:#ffffff;color:#3b5998}.social-buttons .social-icon.twitter{font-size:22px;padding-top:10px;padding-left:2px;border-color:#55acee;background-color:#55acee;color:#ffffff}.social-buttons .social-icon.twitter:hover{background-color:#ffffff;color:#55acee}.social-buttons .social-icon.linkedin{font-size:24px;padding-top:8px;padding-left:1px;background-color:#0976b4;color:#ffffff;border-color:#0976b4}.social-buttons .social-icon.linkedin:hover{background-color:#ffffff;color:#0976b4}.social-buttons .social-icon.youtube{font-size:22px;padding-top:9px;padding-left:0px;background-color:#b00;color:#ffffff;border-color:#b00}.social-buttons .social-icon.youtube:hover{background-color:#ffffff;color:#b00}

@media (max-width: 768px) {
  #box {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    display: block;
    color: white;
    margin-top: 20px;
    font-family: "Raleway";
    font-size: 2.5rem;
    height: 283px;
    margin-left: 26%;
    margin-right: 30px;
    width: 363px;
  }
  .gradient-border {
    --borderWidth: 3px;
    background: white;
    position: relative;
    border-radius: var(--borderWidth);
  }
  .gradient-border:after {
    content: "";
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: #787878;
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    -webkit-animation: animatedgradient 3s ease alternate infinite;
            animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
}

.swiper-container {
  width: 350px;
  height: 350px;
  padding: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

/* counter code */

/*/ start count stats /*/

section#counter-stats {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 100px;
}

.stats {
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

.stats .fa {
  color: #008080;
  font-size: 40px;
}

/*/ end count stats /*/
/*font-family: 'Droid Arabic Naskh', serif;*/

.hline {
  height: 1px;

  background: rgba(100, 100, 200, 0.5);

  /*border-top: 1px solid rgba(200,200,200,0.2);*/
}
.bodycss {
  background: rgba(149, 147, 138, 0.698);
  height: 47px;
  width: 317px;
  -moz-border-radius-bottomleft: 20px;
  -moz-border-radius-topleft: 20px;
  font-family: "Droid Arabic Naskh , serif";
  line-height: 50px;
  font-size: 22px;
  color: rgb(255, 255, 255);
}
.tile1 {
  text-align: center;
  margin-top: 10px;
}

.tile2 {
  text-align: center;
  margin-top: 50px;
}

@media screen and (max-width: 2000px) {
  .weddign {
    margin-left: 30px;
  }
  .tile1 {
    margin-top: -5%;
    position: absolute;
    margin-left: 48.8%;
  }

  .tile2 {
    text-align: center;
    margin-top: 150px;
    margin-left: 50px;
  }
  #box {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    display: block;
    color: white;
    margin-top: 20px;
    font-family: "Raleway";
    font-size: 2rem;
    height: 420px;
    margin-left: 35%;
    width: 530px;
  }
  .gradient-border {
    --borderWidth: 3px;
    background: white;
    position: relative;
    border-radius: var(--borderWidth);
  }
  .gradient-border:after {
    content: "";
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: #787878;

    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    -webkit-animation: animatedgradient 3s ease alternate infinite;
            animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
}

@media screen and (max-width: 1024px) {
  #box {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    display: block;
    color: white;
    margin-top: 20px;
    font-family: "Raleway";
    font-size: 2.5rem;
    height: 420px;
    margin-left: 12%;
    margin-right: 50px;
    width: 543px;
  }
  .gradient-border {
    --borderWidth: 3px;
    background: white;
    position: relative;
    border-radius: var(--borderWidth);
  }
  .gradient-border:after {
    content: "";
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: #787878;
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    -webkit-animation: animatedgradient 3s ease alternate infinite;
            animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
}
@media screen and (max-width: 1364px) {
  #box {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    display: block;
    color: white;
    margin-top: 20px;
    font-family: "Raleway";
    font-size: 2.5rem;
    height: 420px;
    margin-left: 30%;
    margin-right: 50px;
    width: 543px;
  }
  .gradient-border {
    --borderWidth: 3px;
    background: white;
    position: relative;
    border-radius: var(--borderWidth);
  }
  .gradient-border:after {
    content: "";
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: #787878;
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    -webkit-animation: animatedgradient 3s ease alternate infinite;
            animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
}

@media screen and (max-width: 740px) {
  #box {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    display: block;
    color: white;
    margin-top: 20px;
    font-family: "Raleway";
    font-size: 2.5rem;
    height: 280px;
    width: 365px;
  }
  .gradient-border {
    --borderWidth: 3px;
    background: white;
    position: relative;
    border-radius: var(--borderWidth);
  }
  .gradient-border:after {
    content: "";
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: #787878;
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    -webkit-animation: animatedgradient 3s ease alternate infinite;
            animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
}
@media screen and (max-width: 600px) {
  .rise-text {
    left: 0px;
    text-align: -webkit-center;
    margin-top: -1px;
  }
  #box {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    display: block;
    color: white;
    margin-top: 20px;
    font-family: "Raleway";
    font-size: 2.5rem;
    height: 290px;
    margin-left: 1%;
    width: 385px;
  }
  .gradient-border {
    --borderWidth: 3px;
    background: white;
    position: relative;
    border-radius: var(--borderWidth);
  }
  .gradient-border:after {
    content: "";
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: #787878;
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    -webkit-animation: animatedgradient 3s ease alternate infinite;
            animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
}

@media screen and (max-width: 1300px) {
  #box {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    display: block;
    color: white;
    margin-top: 20px;
    font-family: "Raleway";
    font-size: 2.5rem;
    height: 275px;
    margin-left: 2%;
    width: 362px;
  }
  .tile1 {
    margin-top: 20%;
    position: absolute;
    margin-left: 45%;
  }
  .tile2 {
    margin-top: 25%;
  }
  .calender {
    width: 100%;
  }
  .rise-text {
    -webkit-justify-content: center;
            justify-content: center;
  }

  .gradient-border {
    --borderWidth: 3px;
    background: white;
    position: relative;
    border-radius: var(--borderWidth);
  }
  .gradient-border:after {
    content: "";
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: #787878;
    border-radius: calc(2 * var(--borderWidth));
    z-index: -1;
    -webkit-animation: animatedgradient 3s ease alternate infinite;
            animation: animatedgradient 3s ease alternate infinite;
    background-size: 300% 300%;
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  #box {
    margin-left: 32%;
    height: 402px;
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (-webkit-min-device-pixel-ratio: 2), screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi) {
  #box {
    margin-left: 32%;
    height: 402px;
  }
}

.tile {
  width: 20%;
  height: auto;
  display: inline-block;
  background-size: cover;
  cursor: pointer;
  position: relative;

  padding: 0 0 19% 0;
  background-position: center;
}

.image-title {
  width: 100%;
  height: 25%;
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 1rem 1rem;
  font-size: 0.9em;
  font-family: "Helvetica";
  text-align: center;
  line-height: 1em;
  background-color: rgba(0, 0, 0, 0.5);
}

.tile .image-title {
  transition: 0.8s ease;
}

/* Hover effect on image tiles */

.tile:hover .image-title {
  background-color: rgba(0, 0, 0, 0.8);
  height: 40%;
}

/* Pop up CSS light box */

/* Responsive tiles starts here. Breaking points: 1280, 1024, 600, 360 */

@media screen and (max-width: 2000px) {
  .image-container .tile {
    width: 20%;
    padding-bottom: 20%;
    margin-left: 70px;
    margin-top: 50px;
  }
  .main-gallery {
    width: 85%;
    margin-left: 18%;
  }
}

@media screen and (max-width: 1024px) {
  .image-container .tile {
    width: 30%;
    padding-bottom: 30%;

    font-size: 0.8em;
    margin-left: 10px;
  }
  .main-gallery {
    max-width: 90%;
    margin-left: 5%;
  }
}

@media screen and (max-width: 740px) {
  .image-container .tile {
    width: 30%;
    padding-bottom: 30%;

    font-size: 0.8em;
    margin-left: 10px;
  }
  .main-gallery {
    max-width: 90%;
    margin-left: 5%;
  }
}

@media screen and (max-width: 600px) {
  .image-container .tile {
    width: 30%;
    padding-bottom: 30%;

    font-size: 0.8em;
    margin-left: 10px;
  }
  .main-gallery {
    max-width: 90%;
    margin-left: 5%;
  }
}

@media screen and (max-width: 360px) {
  .image-container .tile {
    width: 30%;
    padding-bottom: 30%;

    font-size: 0.8em;
    margin-left: 10px;
  }
  .main-gallery {
    max-width: 90%;
    margin-left: 5%;
  }
}

@media screen and (max-width: 1300px) {
  .image-title {
    width: 100%;
    height: 58%;
    color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 1rem 1rem;
    font-size: 22.9em;
    font-family: "Helvetica";
    text-align: center;
    line-height: 1em;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

